import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { BASE_URL_API, IMG_URL } from '../environment';
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import noLogo from '../img/logo01.svg';
import { Link } from 'react-router-dom';


export const Customers = () => {
  const [view, setView] = useState(false);
  const [cityid, setCityid] = useState(null);
  const [selectcityid, setSelectcityid] = useState("");
  const [last7dayscond, setLast7dayscond] = useState(false);
  const [last15dayscond, setLast15dayscond] = useState(false);
  const [last30dayscond, setLast30dayscond] = useState(false);
  const [notorderyet, setNotorderyet] = useState(false);
  const [accountst, setAccountst] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [loading, setLoading] = useState(false);
  // CUSTOMER LIST
  const [cust, setCust] = useState([]);  
  useEffect(() => {
    fetchData();
  }, [selectcityid, last7dayscond, last15dayscond, last30dayscond, notorderyet, accountst]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(BASE_URL_API + "customers?cityid="+selectcityid+"&last7dayscond="+last7dayscond+"&last15dayscond="+last15dayscond+"&last30dayscond="+last30dayscond+"&notorderyet="+notorderyet+"&accountst="+accountst)
      .then(res => {
        setCust(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
   
    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => setCityid(res.data))
      .catch((err) => console.log(err));
  };
  
  useEffect(() => {
    // Initialize the selectedFilter based on the accountst state
    if (accountst) {
      setSelectedFilter('accountst');
    } else {
      // Check which filter is active and set selectedFilter accordingly
      if (last7dayscond) setSelectedFilter('last7days');
      else if (last15dayscond) setSelectedFilter('last15days');
      else if (last30dayscond) setSelectedFilter('last30days');
      else if (notorderyet) setSelectedFilter('notorderyet');
      else setSelectedFilter('');
    }
  }, [accountst, last7dayscond, last15dayscond, last30dayscond, notorderyet]);
  const handleFilterChange = (e) => {
    const value = e.target.value;

    setSelectedFilter(value);

    switch (value) {
      case 'last7days':
        setLast7dayscond(true);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        break;
      case 'last15days':
        setLast7dayscond(false);
        setLast15dayscond(true);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        break;
      case 'last30days':
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(true);
        setNotorderyet(false);
        setAccountst(false);
        break;
      case 'notorderyet':
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(true);
        setAccountst(false);
        break;
      case 'accountst':
        setAccountst(true); 
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        break;
      default:
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        break;
    }
  };  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  //INACTIVE CUSTOMER
  const handleApiInactive = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to inactive this customer !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Inactive",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
    axios.delete(BASE_URL_API + 'customers/delete/'+id)
      .then(res => {
        swalWithBootstrapButtons.fire(
          "Inactivate!",
          "This Customer has been Inactivated.",
          "success"
        );
        fetchData();
        console.log(res.data)
      }).catch(err => console.log(err));
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire(
        "Cancelled",
        "You did not inactive this customer.",
        "error"
      );
    }
  });
      
  };

  //FETCH ADDRESS
  const [address, setAddress] = useState([]);
  const adrs = (id) => {
    axios.get(BASE_URL_API + 'address/cusId/' + id)
      .then((res) => {
        setAddress(res.data)
      })
      .catch(err => console.log(err));
  };

  //VIEW CUSTOMERS
  const [cusView, setCusView] = useState([]);
  const showUserDetails = (mob,id) => {
    handleApiView(mob);
    adrs(id);
    setView(true)
  }
  const handleApiView = (num) => {
    axios.get(BASE_URL_API + 'customers/'+num)
      .then((res) => {
        setCusView(res.data);
      }).catch(err => console.log(err));
  };

  const yesNoOptions = {
    false: 'Activated',
    true: 'Deleted'
  };
  //TABLE COLUMNS
  const columns = [
    {
      dataField: 'imageURL',
      text: 'Image',
      formatter: (dataField) => {
        if (dataField) {
          return (
            <img src={IMG_URL + dataField} style={{ height: '50px', width: '50px' }} className='rounded' />
          );
        } else {
          return (
            <img src={noLogo} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        }
      },
      headerStyle: { width: '100px' }
    },
    
    {
      dataField: 'name',
      text: 'Name',
      sort: 'asc',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <Link
            to={`${row._id}/${row.mobile}`}
            style={{ textDecoration: 'none', color: '#73be44' }}
          >
            <span style={{ borderBottom: '1px solid transparent' }}>
              {cell}
            </span>
          </Link>
        );
      },
    },    
    {
      dataField: 'mobile',
      text: 'Mobile',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: 'isDelete',
      text: 'Account Status',
      sort: true,
      formatter: (cell) => yesNoOptions[cell],
      // filter: selectFilter({
      //   options: yesNoOptions
      // }),
    }
  ];

  // onClick={() => showUserDetails(row.mobile,row._id)}
  //Pagination
  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };
  return (
   
      <div className="card">
        <h5 className="card-header">Customers</h5>
        
        <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <select onChange={(e)=> setSelectcityid(e.target.value)} value={selectcityid} className="form-select form-select-sm " style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
        <option value="">All Cities</option>
          {cityid && cityid.cities
            ? cityid.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end p-2">
        {/* <label className='mt-2' style={{color:"#73be44", fontWeight:"bold"}}>Order Conditions</label> */}
        <select
          className="form-select form-select-sm"
          style={{ width: '150px', height: '40px', borderColor: "#73be44" }}
          value={selectedFilter}
          onChange={handleFilterChange}
        >
          <option value="">Active</option>
          <option value="accountst">Deleted</option>  {/* Here the acountst is false for active and true for deleted customer here default is false so all active customers are showing */}
          <option value="last7days">Last 7 Days Not order</option>
          <option value="last15days">Last 15 Days Not order</option>
          <option value="last30days">Last 30 Days Not order</option>
          <option value="notorderyet">No Orders Yet</option></select>
      </div>
      </div>
        {/* Customer Table */}
        <div>
      {/* Show loader when loading */}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (    
        <BootstrapTable
        keyField='id' 
        data={cust} columns={columns}
        pagination={paginationFactory(options)} 
        filter={filterFactory()}
        search />
      )}
    </div>    
          {/* View Modal */}
          <Modal size="md" show={view} onHide={() => setView(false)} aria-labelledby="example-modal-sizes-title-md">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Customer Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {
              cusView.map((item) =>
              <>
                <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                {item.imageURL ? (
    <img src={IMG_URL + item.imageURL} style={{ height: "75px", width: "75px" }} />
  ) : (
    <img src={noLogo} style={{ height: "75px", width: "75px" }} />
 ) }                </Col>
              </Row>
            <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <h4>{item.name}</h4>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <h6>{item.email}</h6>
                </Col>
              </Row>

            <br/>
            {/* {
              address.map((itim) =>
              <>
                <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Address</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <p>{itim.location},{itim.landmark},{itim.city}, {itim.state},{itim.PIN} </p>
                </Col>
              </Row>
              </>
              )} */}
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Created on</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <p>{item.createdAt}</p>
                </Col>
              </Row>
              </>
              )}
              </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => setView(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      
    
  )
}
