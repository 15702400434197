import React, {useEffect, useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios';
import { BASE_URL_API, IMG_URL } from '../environment';
import BootstrapTable from 'react-bootstrap-table-next'
import noLogo from '../img/logo01.svg';


function PushNotification() {

  const[add, setAdd]=useState(false);
  const[view, setView]=useState(false);
  const[edit, setEdit]=useState(false);


  const[title, setTitle]=useState("");
  const[content, setContent]=useState("");
  const[time, setTime]=useState("");
  const[date, setDate]=useState("");
  const[image, setImage]=useState("");
  const[upId, setUpId]=useState("");
  const[cityPer, setCityPer]=useState([]);
  const[cityId, setCityId]=useState("");
  const[allItems, setAllItems]=useState([]);
  const[itemId, setItemId]=useState("");
  const[allCondition, setAllCondition]=useState([]);
  const[conditionId, setConditionId]=useState("");
  const[conditiomName, setConditionName]=useState("");  
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  // console.log(title, content,date, time,image)

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const [notification, setNotification] = useState([]);
  
  //getting all notification 
  const fetchData = () => {
    axios.get(BASE_URL_API + 'customNotify/')
      .then(res => {
        console.log(res.data.allCustomNotify);
        setNotification(res.data.allCustomNotify);
      })
      .catch(err => console.log(err));

    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setCityPer(res.data);
      })
      .catch((err) => console.log(err));

      axios
      .get(BASE_URL_API + "notifyCondition/")
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setAllCondition(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []); 

  useEffect(() => {
    if (conditionId === "662d37933f88534fbb71a706" || conditionId === "662e98f5eef1b9364001d865") {
      setShowSecondDropdown(true);
      const cityIdToSend = cityId === "" ? "all" : cityId;
      axios.get(BASE_URL_API + "items/citywiseItems/"+cityIdToSend)
        .then((res) => {
          console.log(res);
          console.log(res.data.filteredItems);
          setAllItems(res.data); 
        })
        .catch((err) => console.log(err));
    } else {
      setShowSecondDropdown(false);
    }
  }, [conditionId, cityId]);
  const [notificationView, setNotificationView] = useState([]);

  const viewBtn = (idd) => {
    handleApiView(idd);
    setView(true);
  };

  //view notification by id
  const handleApiView = (id) => {
    axios
      .get(BASE_URL_API + "customNotify/getById/" + id)
      .then((res) => {
        // console.log(res.data);
        // console.log(res.data.notification);
        // console.log(res.notification);
        setNotificationView(res.data.notification);
      })
      .catch((err) => console.log(err));
  };


  //edit notification by id
  const editBtn = (idd) => {
    axios
      .get(BASE_URL_API + "customNotify/getById/" + idd)
      .then((res) => {
         const notify = res.data.notification[0];
         console.log(notify.date);
        setTitle(notify.title);
        setContent(notify.content);
        setDate(notify.date.split('-').reverse().join('-'));
        setTime(notify.time); 
        setUpId(notify._id);
        setImage(notify.imageURL);
        setCityId(notify.cityid ? notify.cityid : "");
        setItemId(notify.itemid ? notify.itemid : "");
        setConditionId(notify.conditionId && notify.conditionId.length > 0 ? notify.conditionId[0]._id : "");
        setConditionName(notify.conditionId && notify.conditionId.length > 0 ? notify.conditionId[1].condition_name : "");
        setEdit(true);
      })
      .catch((err) => console.log(err));
    setEdit(true);
  };

  //function for update notification
  const handleApiUpdate = (id) => {
    if (!title || !date || !time) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("image", image);
    formData.append("title",title);
    formData.append("content", content);
    formData.append("date", date.split("-").reverse().join("-"));
    formData.append("time", time);
    formData.append("status", "Not send");
    formData.append('cityid', cityId);
    formData.append('itemId', itemId);
    formData.append('condition[0]', conditionId);
    formData.append('condition[1]', conditiomName);

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this notification !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
         

          axios
            .put(BASE_URL_API + "customNotify/edit", formData)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "This notification details has been updated Successfully.",
                "success"
              );
              console.log(res.data);
              setEdit(false);

              fetchData();
              setTitle("");
              setContent("");
              setDate("");
              setImage("");
              setTime("");
              setCityId("");
              setItemId("");
              setConditionId("");
              setConditionName("");
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not Update this notification",
            "error"
          );
          setEdit(false);
        }
      });
  };


  //deactivate notification 
const notificationInactive = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to deactivate this notification !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Deactive!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .put(BASE_URL_API + "customNotify/deactivate/" + id)
        .then((res) => {
          Swal.fire("Deactivated!", "This notification has been deactivated.", "success");
          // console.log(res.data);
          fetchData();
        })
        .catch((err) => console.log(err));
    }
  });
};

//activate 
const notificationActive = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to activate this notification !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Activate!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .put(BASE_URL_API + "customNotify/activate/" + id)
        .then((res) => {
          Swal.fire("Activated!", "This notification has been activated.", "success");
          // console.log(res.data);
          fetchData();
        })
        .catch((err) => console.log(err));
    }
  });
};


//button for action
  const actionsFormatter = (dataField, row) => {
    if (row.isActive) {
      return (
        <>
          <Button
            type="button"
            className="btn btn-sm "
            onClick={() => viewBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="View Details"
            style={{background:"#73be44", color:"#fff"}}
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-secondary btn-sm mx-1"
            onClick={() => editBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
           <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            onClick={() => notificationInactive(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Inactive"
          >
            <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
          </Button> 
        </>
      );
    } else {
      return (
        <>
        <Button
            type="button"
            className="btn btn-light btn-sm disabled "
            // onClick={() => viewBtn(dataField)}
            
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled mx-1"
            // onClick={() => editBtn(dataField)}
           
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          
        <Button
          type="button"
          className="btn btn-primary btn-sm mx-1"
          onClick={() => notificationActive(dataField)}
          data-toggle="tooltip"
            data-placement="top"
            title="Active"
        >
          <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
        </Button> 
        </>
        
      );
    }
  };

  //column for datatable
  const columns = [
    {
      dataField: 'imageURL',
      text: 'Image',
      formatter: (dataField) => {
        if (dataField) {
          return (
            <img alt='' src={IMG_URL + dataField} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        } else {
          return (
            <img alt='' src={noLogo} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        }
      }
    }
    ,
    {
      dataField: 'title',
      text: 'Title',
      sort: 'asc',
      formatter: (dataField) => {
          return (
            <div dangerouslySetInnerHTML={{ __html: dataField }} />
          );
      
      }
   
    },
    {
      dataField: 'content',
      text: 'content',
      sort: 'asc',
      formatter: (dataField) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: dataField }} />
        );
    
    }
    },
    {
      dataField: 'date',
      text: 'Publish Date',
      sort: 'asc',
    },
    {
      dataField: 'time',
      text: 'Publish Time',
      sort: 'asc',
    },
    {
      dataField: 'status',
      text: "Status",
    },

    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormatter,
    },

  ];

  //add notification function
  const addnotification = () => {
    if (!time || !date || !title) {
      window.alert("Date, Time, and title are mandatory");
      return;
    }
  
    const formData = new FormData();
    formData.append('date', date);
    formData.append('time', time);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('image', image);
    formData.append('status', "Not send");
    formData.append('cityid', cityId);
    formData.append('itemId', itemId);
    formData.append('condition[0]', conditionId);
    formData.append('condition[1]', conditiomName);

  
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to add this notification!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Add",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Convert FormData to object for better console logging
          // const formDataObject = Object.fromEntries(formData.entries());
          // console.log(formDataObject);

          axios
          .post(BASE_URL_API + 'customNotify/create', formData)
            .then((res) => {
              console.log(res.data)
              fetchData();
  
              swalWithBootstrapButtons.fire(
                "Added!",
                "A New Notification added successfully.",
                "success"
              );
  
              setAdd(false);
              setDate('');
              setTime('');
              setTitle('');
              setContent('');
              setImage('');
              setCityId('');
              setItemId('');
              setConditionId('');
              setConditionName('');
            }).catch((err) => {
              swalWithBootstrapButtons.fire({
                title: "Error",
                text: "An Error occur!! Try again",
                icon: "error",
              });
              console.log(err)});
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not add this notification",
            "error"
          );
        }
      });
  };

  const rowClasses = (row) => {
    return row.isActive ? "table-lightgreen" : ""; 
  };
  
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // const formats = [
  //   'bold', 'italic', 'underline', 'strike',
  //   'align',
  // ];

 
  // const modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'],
  //     [{ align: [] }],
  //   ],
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  if (file) {
      if (file.size > 1024 * 1024) {
        alert("File size must be within 1MB");
        e.target.value = null;
        return;
      }
      setImage(file);
    }
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    if (selectedCityId === "ALL") {
      setCityId("");
    } else {
        setCityId(selectedCityId);
    }
  };
  const handleConditionChange = (e) => {
    const selectedConId = e.target.value;
    const selectedCon = allCondition.allNotifyCondition.find((con) => con._id === selectedConId);
    if (selectedCon) {
      setConditionId(selectedConId);
      setConditionName(selectedCon.condition_name);
    }
    
    
  };
  return (
    <div className="card">
      <h5 className="card-header">Push Notification</h5>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <Button className="btn" style={{background:"#73be44", color:"#fff"}} onClick={() => setAdd(true)}>
          <i className="fa-solid fa-plus"></i>Create A New Push Notification
        </Button>
      </div>

    {/* datatable for notification */}
      <BootstrapTable keyField="id" data={notification} columns={columns} rowClasses={rowClasses} />

      {/* Create New Notification modal */}
      <Modal
        size="md"
        show={add}
        onHide={() => {setAdd(false); setShowSecondDropdown(false);}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New Notification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <select onChange={(e) => handleCityChange(e)} className="form-select" defaultValue="">
                    <option value="" disabled>
                      Select city
                    </option>
                    <option value="ALL">All City</option>
                    {cityPer && cityPer.cities
                      ? cityPer.cities.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.city}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Condition</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <select onChange={(e) => handleConditionChange(e)} className="form-select" defaultValue="">
                    <option value="" disabled>
                      Select any
                    </option>
                    {allCondition && allCondition.allNotifyCondition
                      ? allCondition.allNotifyCondition.map((i) => (
                          <option key={i._id} value={i._id}>
                            {i.condition_name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            {showSecondDropdown && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Row>
                  <Col xs={6} md={3}>
                    <Form.Label>Select Item</Form.Label>
                  </Col>
                  <Col xs={12} md={9}>
                  <select onChange={(e) => setItemId(e.target.value)} className="form-select" defaultValue="">
                    <option value="" disabled>
                      Select Item
                    </option>
                    {allItems && allItems.filteredItems
                      ? allItems.filteredItems.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.item_name}
                          </option>
                        ))
                      : null}
                  </select>
                  </Col>
                </Row>
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Title</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                     as="textarea"
                     rows={2}
                    placeholder="Enter Title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    autoFocus
                    required
                  />
                   {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        // value={title}
        placeholder='Enter Title'
        onChange={(value) => setTitle(value)}
      /> */}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Content</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                     as="textarea"
                     rows={3}
                    placeholder="Enter content"
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                  />
                  {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        // value={title}
        placeholder='Enter content'
        onChange={(value) => setContent(value)}
      /> */}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Notify Date</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="date"
                    placeholder="Enter A Date"
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      const formattedDate = selectedDate.split("-").reverse().join("-");
                      setDate(formattedDate);
                    }}
                    required
                    min={getFormattedDate(new Date())} 
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Notify Time</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="time"
                    className="modalTextField"
                    style={{ paddingRight: "6px" }}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Upload File </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    accept=".jpg, .jpeg, .png, .gif"
                    // onChange={(e) => setImage(e.target.files[0])}
                    onChange={(e) => handleFileChange(e)}
                  />
                 <Form.Text style={{fontSize:"11px", fontWeight:"bold"}}><b className='text-danger'>* </b>
    Please upload an image file (.jpg, .jpeg, .png, .gif) with a size not exceeding 1MB.
  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addnotification}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
 
      {/* view notification modal */}
      <Modal
        size="md"
        show={view}
        onHide={() => setView(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {notificationView.map((notifi) => (
                <>
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
  {notifi.imageURL ? (
    <img alt='' src={IMG_URL + notifi.imageURL} style={{ height: "75px", width: "75px" }} />
  ) : (
    <img alt='' src={noLogo} style={{ height: "75px", width: "75px" }} />
 ) }
</Col>
                  </Row>
                  <br />
                  <Row>
  <Col xs={6} md={4}>
    <Form.Label>Title</Form.Label>
  </Col>
  <Col xs={12} md={8}>
  <h5 >{notifi.title}</h5>
  </Col>
</Row>
<br />
<Row>
  <Col xs={6} md={4}>
    <Form.Label>Content</Form.Label>
  </Col>
  <Col xs={12} md={8}>
    <h5 >{notifi.content}</h5>
  </Col>
</Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Publish Date</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <h5>{notifi.date}</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Time</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{notifi.time}</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Status</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{notifi.status}</p>
                    </Col>
                  </Row>
                </>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* edit notification modal */}
     <Modal
        size="md"
        show={edit}
        onHide={()=>setEdit(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <select onChange={(e) => handleCityChange(e)} className="form-select" value={cityId}>
                    {/* <option value="" disabled>
                      Select city
                    </option> */}
                    <option value="">All City</option>
                    {cityPer && cityPer.cities
                      ? cityPer.cities.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.city}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Condition</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <select onChange={(e) => handleConditionChange(e)} className="form-select"  value={conditionId}>
                    <option value="" disabled>
                      Select any
                    </option>
                    {allCondition && allCondition.allNotifyCondition
                      ? allCondition.allNotifyCondition.map((i) => (
                          <option key={i._id} value={i._id}>
                            {i.condition_name}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            {/* {showSecondDropdown && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Row>
                  <Col xs={6} md={4}>
                    <Form.Label>Select Item</Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                  <select onChange={(e) => setItemId(e.target.value)} className="form-select" defaultValue="">
                    <option value="" disabled>
                      Select Item
                    </option>
                    {allItems && allItems.cities
                      ? allItems.cities.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.city}
                          </option>
                        ))
                      : null}
                  </select>
                  </Col>
                </Row>
              </Form.Group>
            )} */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Title</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                     as="textarea"
                     rows={2} 
                    value={title}
                    onChange={(e)=>{setTitle(e.target.value)}}
                    autoFocus
                    required
                  />
                  {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={title}
        placeholder='Enter content'
        onChange={(value) => setTitle(value)}
      /> */}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Content</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    as="textarea"
                    rows={3} 
                    value={content}
                    onChange={(e)=>{setContent(e.target.value)}}
                    required
                  />
                  {/* <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={content}
        placeholder='Enter content'
        onChange={(value) => setContent(value)}
      /> */}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Date</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => {setDate(e.target.value)}}
                    required
                    min={getFormattedDate(new Date())} 
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Time</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="time"
                    value={time}
                    onChange={(e)=>{setTime(e.target.value)}}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img alt=''
                        src={IMG_URL +image} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                    <Form.Control
                    type="file"
                    placeholder="Image"
                    accept=".jpg, .jpeg, .png, .gif"
                    // onChange={(e) => setImage(e.target.files[0])}
                    onChange={(e) => handleFileChange(e)}
                  />
                <Form.Text style={{fontSize:"11px", fontWeight:"bold"}}><b className='text-danger'>* </b>
    Please upload an image file (.jpg, .jpeg, .png, .gif) with a size not exceeding 1MB.
  </Form.Text>
                    </Col>
                  </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleApiUpdate(upId)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  );
}

export default PushNotification
